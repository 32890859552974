import {
    useState,
    useEffect,
    useContext
} from 'react'
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
    Input,
    Divider,
    Avatar,
    Paper,
    makeStyles,
    Box,
    InputAdornment,
    ToggleButton,
    ToggleButtonGroup,
    IconButton,
    Stack,
    FormControl,
    MenuItem,
    InputLabel,
    Popper,
    OutlinedInput,
    Checkbox
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CreateIcon from '@mui/icons-material/Create';
import { BACKEND_URL } from '../../constants'

import ConversationContext from '../../context/ConversationContext';

export default function BasicPopover() {
    const { filterValue, setFilterValue, filterBy, setFilterBy, botChannel, setBotChannel, filterTag, setFilterTag, filterID, setFilterID, filter, setFilter, selectedSenderId, setSubFilter, setSubFilterValue, subFilter, filterName, branch, setBranch, department, setDepartment, company, setCompany, id, setId, text, setText, status, setStatus, tags, setTags, setUseFilter, checked, setChecked }: any = useContext(ConversationContext)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [remarks, setRemarks] = useState([]);


    useEffect(() => {
        const fetchTagsChoices = async () => {
          try {
            const response = await fetch(`${BACKEND_URL}/fetch-tags-choices/60126023473`);
            const data = await response.json();
            setRemarks(data.tags);
          } catch (error) {
            console.error("Failed to fetch note from backend:", error);
          }
        };
        fetchTagsChoices();
    }, [selectedSenderId]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCompanyChange = (event: SelectChangeEvent) => {
        setFilterValue(event.target.value);
        setCompany(event.target.value);
        setUseFilter(true)
    };

    const handleDepartmentChange = (event: SelectChangeEvent) => {
        setDepartment(event.target.value);
        setFilterValue(event.target.value);
        setUseFilter(true)
    };

    const handleCampusChange = (event: SelectChangeEvent) => {
        setBranch(event.target.value);
        setFilterValue(event.target.value);
        setUseFilter(true)
    };

    const handleIdChange = (event: any) => {
        setId(event.target.value);
        setFilterValue(event.target.value);
        setUseFilter(true)
    };

    const handleStatusChange = (event: SelectChangeEvent) => {
        setStatus(event.target.value);
        setFilterValue(event.target.value);
        setUseFilter(true)
    };

    const handleTagsChange = (event: SelectChangeEvent) => {
        setTags(event.target.value);
        setFilterValue(event.target.value);
        setUseFilter(true)
    };

    const handleTextChange = (event: any) => {
        setText(event.target.value);
        setFilterValue(event.target.value);
        setUseFilter(true)
    };

    const handleCheckBoxChange = (event: any) => {
        setChecked(!checked);
        setFilterValue(event.target.value);
        setUseFilter(true)
    };

    const resetFilter = (event: any) => {
        setUseFilter(false)
        setBranch('') 
        setDepartment('') 
        setCompany('') 
        setId('') 
        setText('') 
        setStatus('') 
        setTags('') 
        setChecked(false)
    };

    const open = Boolean(anchorEl);
    const iid = open ? 'simple-popover' : undefined;

  return (
    <div>
        <IconButton aria-describedby={iid} onClick={handleClick}>
            <ManageSearchOutlinedIcon/>
        </IconButton>
        {/* <Button aria-describedby={id} variant="contained" onClick={handleClick}>
            Open Popover
        </Button> */}
        <Popover
            id={iid}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            marginThreshold={16}
        >
            <Box p={2}>
                <Typography variant="body2" fontWeight="bold" sx={{ p: 1, color: '#2196f3' }}>COMPANY</Typography>
                <FormControl variant="outlined" size="small" sx={{ minWidth: 200, maxWidth: 200 }}>
                    {/* <InputLabel id="demo-simple-select-standard-label">Company</InputLabel> */}
                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={company}
                    onChange={handleCompanyChange}
                    label="Company"
                    >
                    <MenuItem value={'UCSI Education Sdn Bhd'}>UCSI Education Sdn Bhd</MenuItem>
                    </Select>
                </FormControl>
                <Typography variant="body2" fontWeight="bold" sx={{ p: 1, color: '#2196f3' }}>DEPARTMENT</Typography>
                <FormControl variant="outlined" size="small" sx={{ minWidth: 200, maxWidth: 200  }}>
                        {/* <InputLabel id="demo-simple-select-standard-label">Department</InputLabel> */}
                        <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={department}
                        onChange={handleDepartmentChange}
                        label="Company"
                        >
                        <MenuItem value={'student'}>Student Enrolment Centre</MenuItem>
                        </Select>
                </FormControl>
                <Typography variant="body2" fontWeight="bold" sx={{ p: 1, color: '#2196f3' }}>BRANCH</Typography>
                <FormControl variant="outlined" size="small" sx={{ minWidth: 200, maxWidth: 200 }}>
                    {/* <InputLabel id="campus-standard-label">Branch</InputLabel> */}
                    <Select
                    labelId="campus-standard-label"
                    id="campus-select-standard"
                    value={branch}
                    onChange={handleCampusChange}
                    label="Campus"
                    >
                    <MenuItem value={'112508211735810'}>Kuala Lumpur</MenuItem>
                    <MenuItem value={'106366842382598'}>Kuching</MenuItem>
                    <MenuItem value={'3'}>Springhill</MenuItem>
                    <MenuItem value={'4'}>College</MenuItem>
                    </Select>
                </FormControl>
                <Typography variant="body2" fontWeight="bold" sx={{ p: 1, color: '#2196f3' }}>SEARCH BY ID</Typography>
                <FormControl variant="outlined" sx={{ minWidth: 200, maxWidth: 200 }}>
                    {/* <InputLabel htmlFor="input-with-icon-adornment">
                    Search By ID
                    </InputLabel> */}
                    <OutlinedInput
                    id="input-with-icon-adornment"
                    value={id}
                    onChange={handleIdChange}
                    startAdornment={
                        <InputAdornment position="start">
                            <PersonSearchIcon fontSize='small' />
                        </InputAdornment>
                    }
                    />
                </FormControl>
                <Typography variant="body2" fontWeight="bold" sx={{ p: 1, color: '#2196f3' }}>Status</Typography>
                <FormControl variant="outlined" sx={{ minWidth: 200, maxWidth: 200  }}>
                    {/* <InputLabel id="demo-simple-select-standard-label">Status</InputLabel> */}
                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={status}
                    onChange={handleStatusChange}
                    label="Tag"
                    >
                    <MenuItem value={'Online'}>Online</MenuItem>
                    <MenuItem value={'Fallback'}>Fallback</MenuItem>
                    <MenuItem value={'Call In'}>Call In</MenuItem>
                    <MenuItem value={'Chatting'}>Chatting</MenuItem>
                    </Select>
                </FormControl>
                <Typography variant="body2" fontWeight="bold" sx={{ p: 1, color: '#2196f3' }}>Tag</Typography>
                <FormControl variant="outlined" size="small" sx={{ minWidth: 200, maxWidth: 200 }}>
                    {/* <InputLabel id="tag-select-standard-label">Tag</InputLabel> */}
                    <Select
                    labelId="tag-select-standard-label"
                    id="tag-select-standard"
                    value={tags}
                    onChange={handleTagsChange}
                    label="Remark"
                    >
                    {remarks && remarks.length > 0 && remarks.map((remark: any) => (
                        <MenuItem
                        key={remark}
                        value={remark}
                        >
                        {remark}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                <Typography variant="body2" fontWeight="bold" sx={{ p: 1, color: '#2196f3' }}>SEARCH BY TEXT</Typography>
                <FormControl variant="outlined" size="small" sx={{ minWidth: 200, maxWidth: 200 }}>
                    {/* <InputLabel htmlFor="input-with-icon-adornment">
                    Search By Text
                    </InputLabel> */}
                    <OutlinedInput
                    id="input-with-icon-adornment"
                    value={text}
                    onChange={handleTextChange}
                    startAdornment={
                        <InputAdornment position="start">
                            <CreateIcon fontSize='small' />
                        </InputAdornment>
                    }
                    />
                </FormControl>
                <Stack direction="row" alignItems="center">
                    <FormControl variant="outlined" size="small">
                        <Checkbox
                            checked={checked}
                            onChange={handleCheckBoxChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            />
                    </FormControl>
                    <Typography variant="body2" fontWeight="bold" sx={{ p: 1, color: '#2196f3' }}>SHOW ASSIGNED ONLY</Typography>
                </Stack>
                <Stack direction="row">
                    <Button sx={{color: '#4dabf5', m: 1}} onClick={resetFilter}>
                        Reset
                    </Button>
                    <Button sx={{color: '#4dabf5', m: 1}} onClick={handleClose}>
                        Submit
                    </Button>
                </Stack>
            </Box>
        </Popover>
    </div>
  );
}